import logo from './logo.svg';
import './App.css';
import { useState } from "react";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
          <Like/>
      </header>
    </div>
  );
}

function Like(){
  const [icon,iconState] = useState(0);
  console.log(icon);
  return (<a onClick={()=>iconState(1-icon)}>{icon===0? "😁":"😎"}</a>);
}
export default App;
